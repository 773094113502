import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Logout from '../components/Logout';
import NotFound from '../views/404.vue';

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
		name: 'login',
		path: '/login',
		component: ()=>import(/* webpackChunkName: "about" */ '../components/login/Login.vue'),
	},
  {
		name: 'forget',
		path: '/forget',
		component: ()=>import(/* webpackChunkName: "about" */ '../components/forget/Forget.vue'),
	},
  {
		name: 'forget-sended',
		path: '/forget-sended',
		component: ()=>import(/* webpackChunkName: "about" */ '../components/forget/ForgetSended.vue'),
	},
  {
		name: 'change-pass',
		path: '/change-pass',
		component: ()=>import(/* webpackChunkName: "about" */ '../components/forget/change/ChangePass.vue'),
    props: (route) => ({ token: route.query.t }),
	},
  {
		name: 'feedback',
		path: '/feedback',
		component: ()=>import(/* webpackChunkName: "about" */ '../components/feedback/Feedback.vue'),
	},
  {
    path: '/logout',
    component: Logout
  },
  {
    name: 'signup',
		path: '/signup',
		component: ()=>import(/* webpackChunkName: "about" */ '../components/signup/Signup.vue'),
	},
  {
    path: '/lists',
    component: () => import(/* webpackChunkName: "about" */ '../components/lists/Lists.vue'),
  },
  {
    path: '/lists/:listId',
    component: () => import(/* webpackChunkName: "about" */ '../components/list-items/ListItems.vue'),
    props: (route) => ({ accessLink: route.query.ac }),
  },
  {
    path: '/contacts',
    component: () => import(/* webpackChunkName: "about" */ '../components/my-contacts/MyContacts.vue'),
  },
  {
    path: '/products',
    component: () => import(/* webpackChunkName: "about" */ '../components/products/Products.vue'),
  },
  {
    path: '/vocabulary',
    component: () => import(/* webpackChunkName: "about" */ '../components/vocabulary/Vocabulary.vue'),
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "about" */ '../components/settings/Settings.vue'),
  },
  {
    path: '/users',
    component: () => import(/* webpackChunkName: "about" */ '../components/users/Users.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Help.vue')
  },
  { path: '/language/:lang'},
  { path: '*',  component: NotFound },
  //{ path: '*',  beforeEnter() { window.location.href = "/404" } },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
