<template>
  <footer class="footer">
    <div class="container-footer">
         <div class='organization'>{{foo.organization['ru']}} &copy; {{getYear}}</div>
         <div class="feedback"><router-link to="/feedback">{{feedback[lang]}}</router-link></div>
    </div>
  </footer>
</template>

<script>
  export default {
  name: 'Footer',
  props: {
    foo: {
      organization: Object,
      country: Object,
    },
    lang: String,
  },
  data(){
    return{
      feedback: {
        ru: 'Обратная связь',
        en: 'Feedback'
      }
    }
  },
  computed: {
    getYear: function() {
       return new Date().getFullYear();
    },
  }
}
</script>
