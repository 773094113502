<template>
  <div class="home" :class="{containerBox: userRole=='roleUser'||userRole=='roleAdmin'}">
    <img alt="Vue logo" class='front-logo' src="../assets/logo-front.png">
    <HelloWorld v-if="userRole == 'roleGuest'" :msg = "voc.h1[lang]"/>
    <FrontUser v-if="userRole == 'roleUser' || userRole == 'roleAdmin'" :msg = "voc.h1[lang]"/>
    <div class="generate"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import FrontUser from '@/components/FrontUser.vue'
import translations from './translations';

export default {
  name: 'Home',
  components: {
    HelloWorld, FrontUser
  },
  metaInfo() {
    return {
      title: this.voc.title[this.lang],
      meta: [
        //{ name: 'description', content: this.voc.description['ru'] },
        { name: 'description', content: "Онлайн-приложение список Listtok. Здесь вы cможете составить онлайн спискок для покупок в магазинах, список вещей и желаний, вести совместные списки, а также отмечать позиции, которые уже выполнены." },
        { name: 'keywords', content: 'Listok, Листок, список покупок онлайн, онлайн список покупок, список покупок составить онлайн, совместный список покупок, общий список покупок, совместные покупки, common shopping list, joint shopping list, online shopping list, покупки онлайн, список покупок, онлайн приложение, приложение, online app, app' },
        { vmid: 'og:title', property: 'og:title', content: 'Listok - список покупок онлайн' },
        { vmid: 'og:description', property: 'og:description', content: this.voc.ogdescription[this.lang] },
      ],
    }
  },
  data() {
    return {
      voc: translations,
    }
  },
  computed: {
    lang(){
      return this.$store.getters.lang;
    },
    userRole() {
      return this.$store.getters.userRole;
      //return this.$store.state.entry.userRole;
    },
  },
}
</script>
