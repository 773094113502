<template>
	<li :class="classLi" @click="$emit('hide')">
		<!--router-link :class="classA" :to="{ path: this.menuI.url, query: this.menuI.options}">{{menuI.label}}</router-link-->
		<router-link :class="classA" :to="{ path: this.menuI.url}">{{ menuI.label[lang] }}</router-link>
		<!--a :class="classA" :href="menuI.url[0]" v-on="clickListeners" > {{menuI.label}} </a-->
		<ul :class="classUl" v-if="isNesting">
			<MenuItem v-for="(menuI, index) in menuI.items" :menuI="menuI" :layer="layer+1" :lang="lang" :key="index"/>
		</ul>
	</li>
</template>


<script>

export default {
  name: 'MenuItem',
  props: {
  	menuI: {
      type: Object,
      required: true
    },
    layer:{
    	type: Number,
    	required: true
    },
		lang:{
			type: String,
			required: true
		}
  },

  computed:{
		isNesting(){
			return this.menuI.items.length>0;
		},
  	classLi:function(){
  		return {
	  		'nav-item':( this.layer<3 ),//true,//
	  		'dropdown':( this.isNesting && this.layer<3 ),//p.isNesting,//
	  		//'dropdown-submenu':(p.isNesting&&p.layer==2)
	  	}
  	},
  	classA:function(){
  		return {
  			'nav-link':(this.layer<3),
  			//'dropdown-item': p.isNesting&&p.layer==2,
  			'dropdown-toggle': (this.isNesting && this.layer==1)//p.isNesting,
  		}
  	},
  	classUl:function(){
  		return {
  			'dropdown-menu':(this.isNesting && this.layer==1),//true//p.isNesting//
  			'dropdown-item':(this.isNesting && this.layer==2),
  		}
  	},
  },
  /*mounted: function(){

  	this.$on('toggle', this.dropdownList);
  	//console.log(vm.$listeners);
  },*/
  //methods:{
  //    dropdownList: function(el){
  //      //v-on:toggle="dropdownList"
  //      //console.log(event);
  //      //console.log(el);
  //      el.children[1].classList.toggle("show");
  //
  //    }
  //  },
/*  created: function () {
  	console.log(this.$listeners)
  }*/
  /*methods:{
      dropdownList: function(event){
        console.log(event);
        //console.log(event);
      }
    }*/
  /*data: {
  	isItems: (this.$props.menuI.items.length>0)
  }*/
}
</script>
