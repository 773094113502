export default {
  title: {
    en: 'Listtok - список покупок онлайн (shopping list online)',
    ru: 'Listtok - список покупок онлайн'
  },
  description: {
    en: 'Online Listtok list app. Here you can create an online shopping list in stores, a list of things and wishes, maintain joint shopping lists, as well as mark the items that have already been completed.',
    ru: 'Онлайн-приложение список Listtok. Здесь вы cможете составить онлайн спискок для покупок в магазинах, список вещей и желаний, вести совместные списки, а также отмечать позиции, которые уже выполнены.',
  },
  ogdescription: {
    en: 'Online shopping list',
    ru: 'Онлайн список покупок'
  },
  h1: {
    en: 'Online app shopping list «Listtok»',
    ru: 'Онлайн-приложение cписок покупок «Listtok»'
  },
  welcome: {
    en: "Welcome to the online shopping list app Listtok, designed to create personal or collaborative shopping lists. Here you can create online: <b>shopping list</b>, <b>to-do list</b>, <b> travel list </b>, <b>wishlist</b> or any other. Sign in to access all of the app's features. If you do not have an account yet, please register.",
    ru: "Добро пожаловать в онлайн приложение список покупок Listtok, предназначенное для составления персональных или совместных списков. Здесь вы можете составить онлайн: <b>список покупок</b>, <b>список дел</b>, <b>список вещей в дорогу</b>, <b>список желаний</b> или любой другой. Для доступа ко всем функциям приложения выполните вход. Если у вас еще нет аккаунта пройдите регистрацию."
  },
  login: {
    en: 'Login',
    ru: 'Вход'
  },
  register: {
    en: 'Register',
    ru: 'Регистрация'
  },
  options: {
    en: 'Online listing features',
    ru: 'Возможности онлайн списка'
  },
  abiliti_1_title: {
    en: 'Online list',
    ru: 'Онлайн список'
  },
  abiliti_1_body: {
    en: 'Create your own shopping lists for shopping trips. Mark completed.',
    ru: 'Создавать собственные списки покупок для походов в магазин. Отмечать выполненное.'
  },
  abiliti_2_title: {
    en: 'Mobile version',
    ru: 'Мобильная версия'
  },
  abiliti_2_body: {
    en: 'Web application is always at hand, as it is adapted to mobile devices.',
    ru: 'Веб приложение всегда под рукой так как оно адаптировано под мобильные устройства.'
  },
  abiliti_3_title: {
    en: 'Any lists',
    ru: 'Любые списки'
  },
  abiliti_3_body: {
    en: 'Not only a list of purchases, but also any other (for example, a list of affairs).',
    ru: 'Не только список покупок, но и любой другой (например, список дел).'
  },
  abiliti_4_title: {
    en: 'Common lists',
    ru: 'Совместные списки'
  },
  abiliti_4_body: {
    en: 'Commot list for joint purchases. Any number of other users of the system in the list.',
    ru: 'Общий список для совместных закупок. Любое количество других пользователей системы в списке.'
  },
  abiliti_5_title: {
    en: 'Share list',
    ru: 'Делиться списком'
  },
  abiliti_5_body: {
    en: 'Send your lists through messengers at a secure link (registration is not required to view it).',
    ru: 'Отправляйте списки через мессенджеры по защищенной ссылке (для просмотра по ней не требуется регистрация).'
  },
  abiliti_6_title: {
    en: 'Base of goods',
    ru: 'База данных товаров'
  },
  abiliti_6_body: {
    en: 'General system, constantly updated base of goods. The ability to add new goods to your dictionary.',
    ru: 'Общая системная, постоянно обновляемая база товаров. Возможность добавления новых товаров в свой словарь.'
  },
  li_1: {
    en: 'Create your own shopping lists for shopping trips. Mark completed',
    ru: 'Создавать собственные списки покупок для походов в магазин. Отмечать выполненное'
  },
  li_2: {
    en: 'Create a common list for joint purchases',
    ru: 'Создавать общий список для совместных закупок'
  },
  li_3: {
    en: "Share your shopping lists or be a member of strangers for joint purchases",
    ru: 'Делиться своими списками покупок или быть участником чужих для совместных покупок'
  },
  li_4: {
    en: 'Shared shopping lists with unlimited other users of the system',
    ru: 'Совместные списки покупок с неограниченным количеством других пользователей системы'
  },
  li_5: {
    en: 'General system, constantly updated database of goods',
    ru: 'Общая системная, постоянно обновляемая база товаров'
  },
  li_6: {
    en: 'Ability to add new products to your dictionary',
    ru: 'Возможность добавления новых товаров в свой словарь'
  },
  li_7: {
    en: 'Use the app as a to-do list, a to-do list, or a wishlist',
    ru: 'Использовать приложение как спискок дел, спискок вещей в дорогу или спискок желаний'
  },
  contacts: {
    en: 'My contacts',
    ru: 'Мои контакты'
  },
  lists: {
    en: 'Lists',
    ru: 'Списки'
  },
}
