import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {mapState, mapActions} from 'vuex'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import Meta from 'vue-meta';
Vue.use(Meta);

Vue.use(VueAxios, axios)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  watch: {
    '$route' (to, from) {
        if (to.path.search(/^\/language/)>-1)  {
          //Можно обработку сделать на уровне компонента Language, но, т.к. он всегда будет загружаться,
          //то будет вызываться цепь уничтожений и пересозданий всех компонентов на которых вызвается смена языка
          //Данный способ работает быстрее и дешевле
          this.changeLanguage(to,from);
        }
        this.$store.commit('clearError');
      },
  },
  computed: {
    ...mapState(['lang', 'entry']),
  },
  methods: {
    ...mapActions(['getMenu']),
    changeLanguage(to, from){
			this.$router.replace(from.path);
			if (this.lang!=to.params.lang)
			{
				this.setLang(to.params.lang);
				this.sendLang(to.path);
			}
		},
		sendLang(path){
      axios.put(path)
        .catch(e=>{
          this.$store.dispatch('setError', e);
      });
		},
    setLang(lang){
			this.$store.commit('setLang', lang);
		},
  },

  created(){
		this.getMenu();
	},
  // beforeMount(){
  //   window.YandexRotorSettings = {
  //     WaiterEnabled: true
  //   }
  // },
  render: h => h(App),
  mounted() {
    // () => document.dispatchEvent(new Event("x-app-rendered"))
    document.dispatchEvent(new Event("x-app-rendered"))

    let intervalId = setInterval(function() {
      if (document.querySelectorAll('.container').length > 0) {
          window.YandexRotorSettings.IsLoaded = true;
          clearInterval(intervalId);
      }
    }, 1000);
    // this.$nextTick(function () {
    //   setTimeout(function() {
    //     window.YandexRotorSettings.IsLoaded = true;
    //   }, 5000);
    // })
  }
}).$mount('#app')
