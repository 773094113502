import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'ru',
    error: {},
    message: {},
    entry:{
      nav: {
        brandLabel: {},
        brandUrl: '',
        menu: [],
      },
      userRole: '',
      userId: '',
      appParams:{
        organization:{},
        country:{},
      },
    },
  },
  mutations: {
    setLang(state, lang) {
			state.lang=lang;
		},
		clearError(state){
			//state.error=Object.assign({});
			//if ("message" in state.error)
			state.error={};
		},
    clearMessage(state){
      state.message={};
    },
  },
  actions: {
    getMenu({state}){
			return axios.get('/menu')
				.then(response=>{
					// console.log(response.data)
					let d = response.data;
					state.entry.nav.brandLabel = d.brandLabel;
					state.entry.nav.brandUrl = d.brandUrl;
					state.entry.nav.menu = d.menu;
          state.lang = d.lang;
          state.entry.userRole = d.userRole;
          state.entry.userId = d.userId;

					state.entry.appParams.organization = d.appParams.organization;
					state.entry.appParams.country = d.appParams.country;

					return Promise.resolve(d.lang);

				})
				.catch(e => {
					state.error = e.response.data;

				});
		},
    setError({state, dispatch}, error){
      //console.log(JSON.stringify(error));
      //console.log(error);
      //state.error=Object.assign({}, error);
      if (error.response instanceof Object){
        state.error=error.response.data;
        if (("status" in state.error) && (state.error.status==403))
          dispatch('getMenu');
      }
      else
        state.error=error;
        let elem = document.getElementById('wrapper')
        elem.scrollIntoView({ behavior: "smooth" });
    },
    setMessage({state, dispatch}, message){
      state.message = message;
      setTimeout(()=>state.message={}, 3000)
    },
  },
  getters: {
    lang: state => state.lang,
    userRole: state => state.entry.userRole,
    userId: state => state.entry.userId,
    error(state){
      if ((state.error instanceof Object) && ('message' in state.error)){
        return ('name' in state.error) ? `${state.error.name}. ${state.error.message}`.split(';') : state.error.message.split(';');
      }
      else if (JSON.stringify(state.error)=='{}' || state.error=='')
        return false;
      return [state.error];
    },
    message(state){
      if(JSON.stringify(state.message)=='{}' || state.message=='') {
        return false;
      } else return state.message;
    },
  },
  modules: {
  }
})
