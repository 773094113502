<template>
  <div class="hello">
    <h1>404 Page Not Found</h1>
  </div>
</template>

<script>
import axios from 'axios';
import VueAxios from 'vue-axios';

export default {
  metaInfo() {
    return {
      title: "404 Page Not Found",
      meta: [
        { name: 'robots', content: 'noindex' }
      ]
    }
  },
  methods: {
    fetchData(){
      axios.get('/404')
      .then(response=>{
      })
      .catch(e=>{
        this.$store.dispatch('setError', e);
      })
    },
  },
  created(){
    this.fetchData();
  },
};
</script>
