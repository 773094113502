<template>
      <div class="hello">
        <h1>{{ voc.h1[lang] }}</h1>
        <div class='front-buttons'>
          <router-link to='/lists' class='button'>{{voc.lists[lang]}}</router-link>
          <router-link to='/contacts' class='button'>{{voc.contacts[lang]}}</router-link>
        </div>
      </div>
</template>

<script>
import translations from '../views/translations';

export default {
  name: 'FrontUser',
  props: {
    msg: String
  },
  data() {
    return {
      voc: translations,
    }
  },
  computed: {
    lang(){
      return this.$store.getters.lang;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #fff;
}
a:hover {
  text-decoration: none;
  color: #fff;
}
.front-buttons {
  width: 250px;
  margin: 0 auto;
}
.button {
   border: 1px solid green;
   padding: 20px 50px;
   margin: 20px 0;
   display: block;
   background-color: #4CAF50;
}
.button:hover {
  border: 1px solid black;
}
a.button  {
  font-size: 20px;
}
</style>
