<template>
  <main id="main" class="container">
    <template v-if="errorMessage">
      <transition name="fade">
        <div class="alert alert-danger alert-dismissible">
          <button type="button" class="close" @click="hide">
            <span>&times;</span>
          </button>
          <p v-for="message in errorMessage" v-if="errorMessage">
            <strong>Ошибка:</strong> {{ message }}
          </p>

        </div>
      </transition>
    </template>

    <template v-if='message'>
      <div class='message' :style="{backgroundColor: COLOR[message.color]}">
        <button type="button" class="close" @click="hideMessage">
          <span>&times;</span>
        </button>
        <p v-if='message'>
          {{ message.data }}
        </p>
      </div>
    </template>

    <router-view/>
  </main>
</template>

<script>
export default {
  name: 'Content',
  data(){
    return{
      COLOR:{
        red:'#FFB6C1',
        green:'#B4EEB4'
      },
    }
  },
  computed:{
		errorMessage(){
			return this.$store.getters.error;
		},
    message(){
      return this.$store.getters.message
    },
	},
	methods:{
		hide(e){
			//this.$props.merrors=false;
			//this.$root.error={};
			this.$store.commit('clearError');
			//console.log(e.target.parentNode);
			//e.target.parentNode.style.display='none';
		},
    hideMessage(e){
      this.$store.commit('clearMessage');
    },
  },
}
</script>
