<template>
  <div class="hello">
    <div class="box">
      <h1>{{ msg }}</h1>
      <p class="text-left" v-html="this.voc.welcome[lang]"></p>
      <p>
        <router-link to='/login' class='button left-right auth' role="button" aria-disabled="true">{{voc.login[lang]}}</router-link>
        <router-link to='/signup' class="button grey left-right auth" role="button" aria-disabled="true">{{voc.register[lang]}}</router-link>
      </p>
    </div>

    <h3>{{this.voc.options[lang]}}</h3>
    <div class="abilities box marg-top-button">
      <div class="ability">
        <div class="ability-img"><img src="../assets/list.png"/></div>
        <div class="ability-title">{{this.voc.abiliti_1_title[lang]}}</div>
        <div class="ability-body">{{this.voc.abiliti_1_body[lang]}}</div>
      </div>
      <div class="ability">
        <div class="ability-img"><img src="../assets/mobile.png"/></div>
        <div class="ability-title">{{this.voc.abiliti_2_title[lang]}}</div>
        <div class="ability-body">{{this.voc.abiliti_2_body[lang]}}</div>
      </div>
      <div class="ability">
        <div class="ability-img"><img src="../assets/multi-func.png"/></div>
        <div class="ability-title">{{this.voc.abiliti_3_title[lang]}}</div>
        <div class="ability-body">{{this.voc.abiliti_3_body[lang]}}</div>
      </div>
      <div class="ability">
        <div class="ability-img"><img src="../assets/common-list.png"/></div>
        <div class="ability-title">{{this.voc.abiliti_4_title[lang]}}</div>
        <div class="ability-body">{{this.voc.abiliti_4_body[lang]}}</div>
      </div>
      <div class="ability">
        <div class="ability-img"><img src="../assets/share.png"/></div>
        <div class="ability-title">{{this.voc.abiliti_5_title[lang]}}</div>
        <div class="ability-body">{{this.voc.abiliti_5_body[lang]}}</div>
      </div>
      <div class="ability">
        <div class="ability-img"><img src="../assets/database.png"/></div>
        <div class="ability-title">{{this.voc.abiliti_6_title[lang]}}</div>
        <div class="ability-body">{{this.voc.abiliti_6_body[lang]}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import translations from '../views/translations';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      voc: translations,
    }
  },
  computed: {
    lang(){
      return this.$store.getters.lang;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #fff;
}
</style>
