<template></template>

<script>
import axios from 'axios'
import VueAxios from 'vue-axios'

  export default {
      name:'Logout',
      beforeRouteEnter (to, from, next) {
        next(vm => {
            axios.post(to.path)
            .then(resolve=>{
              vm.$store.dispatch('getMenu').then(()=>{
                vm.$router.push('/');
              });
            })
            .catch(e => {
							console.log(e);
               vm.$store.dispatch('setError', e);
            });
          })
      }

};

</script>
