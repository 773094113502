<template>
	<nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
      <router-link class="navbar-brand logo" :to="{ path: nav.brandUrl}"><img @click="closeMenu" src="../assets/logo.png"></router-link>
      <button class="navbar-toggler" type="button" @click="toogleMenu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse flex-row-reverse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <MenuItem @hide="toogleMenu" v-for="(menuI, index) in nav.menu" :menuI="menuI" :layer="1" :lang="lang" :key="index"/>
        </ul>
      </div>
    </nav>
</template>

<script>
  import MenuItem from './MenuItem.vue'
  export default {
    name: 'Navbar',
    props: {
      nav: {
          brandLabel: Object,
          brandUrl: String,
          menu: Array,
      },
      lang: String,
    },
    components: { MenuItem },

    methods:{
			toogleMenu(){
				document.getElementById('navbarSupportedContent').classList.toggle("show");
			},
      closeMenu(){
        if(document.getElementById('navbarSupportedContent').classList.contains("show")) document.getElementById('navbarSupportedContent').classList.remove("show")
      },
    },
  created(){
    window.addEventListener('click', (e)=>{
      if(!document.getElementsByTagName('nav')[0].contains(e.target) &&
          document.getElementById('navbarSupportedContent').classList.contains("show")) this.closeMenu()
    })
  }
}
</script>

<style>
.dropdown:hover .dropdown-menu {
  display: block!important;
  background-color: #252d36;
}
</style>
