<template>
  <div id="app">
    <div id="wrapper">
      <Navbar :nav="entry.nav" :lang="lang" />
      <Content/>
    </div>
    <Footer :foo="entry.appParams" :lang="lang" />
  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'
import Navbar from './components/Navbar.vue'
import Content from './components/Content.vue'
import Footer from './components/Footer.vue'


export default {
    name: 'App',
    data() {
      return {
        info: null,
        //test: "test value",
        hello: "hello",
      };
    },
    components: { Navbar, Content, Footer },
    computed: {
      ...mapState(['lang', 'entry']),
      /*test() {
        return this.$store.state.lang;
      },*/
    },

}
</script>

<style>
  @import './assets/style.css';
</style>
